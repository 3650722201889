import "./App.css";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Login from "./components/Login";
import Navigation from "./components/Navigation";
import Profile from "./components/Profile";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./components/Register";
import { useState } from "react";
import { fetchMyProfile } from "./services";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import ProfileEdit from "./components/ProfileEdit";

function App() {
  const [state, setState] = useState({
    profile: undefined,
    rerender: true,
  });

  onAuthStateChanged(auth, (user) => {
    if (user && state.rerender) {
      console.log(user.uid);
      updateProfile();
    }
  });

  async function updateProfile() {
    const result = await fetchMyProfile();
    if (result) {
      setState({ ...state, profile: result, rerender: false });
    }
  }

  return (
    <div className="App min-h-screen flex flex-col">
      <BrowserRouter>
        <Navigation profile={state.profile} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="profile/:uid" element={<Profile />} />
          <Route
            path="profile/edit"
            element={<ProfileEdit profile={state.profile} />}
          />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
