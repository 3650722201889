import React from "react";

export default function SkillChip(props) {
  return (
    <div className="flex items-center gap-4 bg-slate-800 rounded-xl p-2 pl-4 text-green-400">
      {props.icon}
      <div>
        <p className="text-sm text-white">{props.title}</p>
        <p className="text-xs text-slate-400">{props.description}</p>
      </div>
    </div>
  );
}
