import React from "react";
import { useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  Input,
  ButtonGroup,
  Switch,
  Textarea,
  Select,
  SelectItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";
import {
  Activity,
  Braces,
  BrushFill,
  CardText,
  Cpu,
  CurrencyDollar,
  PenFill,
  Plus,
} from "react-bootstrap-icons";
import SkillChip from "./SkillChip";
import ProjectChip from "./ProjectChip";

export default function ProfileEdit(props) {
  const [profile, setProfile] = useState(props.profile);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [skill, setSkill] = useState({
    name: "",
    icon: 1,
    seniority: 1,
  });

  return (
    <>
      <Modal
        className="dark"
        classNames={{ base: "text-slate-200" }}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        backdrop="blur"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add a skill
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-col mx-auto gap-3 w-full">
                  <div className="flex items-center gap-4 mx-auto">
                    <p>Icon</p>
                    <ButtonGroup>
                      <Button
                        isIconOnly
                        className="text-lg"
                        color={skill.icon === 1 ? "success" : "default"}
                        onClick={() => setSkill({ ...skill, icon: 1 })}
                      >
                        <Braces />
                      </Button>
                      <Button
                        isIconOnly
                        className="text-lg"
                        color={skill.icon === 2 ? "success" : "default"}
                        onClick={() => setSkill({ ...skill, icon: 2 })}
                      >
                        <BrushFill />
                      </Button>
                      <Button
                        isIconOnly
                        className="text-lg"
                        color={skill.icon === 3 ? "success" : "default"}
                        onClick={() => setSkill({ ...skill, icon: 3 })}
                      >
                        <Activity />
                      </Button>
                      <Button
                        isIconOnly
                        className="text-lg"
                        color={skill.icon === 4 ? "success" : "default"}
                        onClick={() => setSkill({ ...skill, icon: 4 })}
                      >
                        <CardText />
                      </Button>
                      <Button
                        isIconOnly
                        className="text-lg"
                        color={skill.icon === 5 ? "success" : "default"}
                        onClick={() => setSkill({ ...skill, icon: 5 })}
                      >
                        <Cpu />
                      </Button>
                    </ButtonGroup>
                  </div>
                  <Input
                    label="Skill"
                    value={skill.name}
                    onValueChange={(e) => setSkill({ ...skill, name: e })}
                  />
                  <ButtonGroup>
                    <Button
                      color={skill.seniority === 1 ? "success" : "default"}
                      onClick={() => setSkill({ ...skill, seniority: 1 })}
                    >
                      Beginner
                    </Button>
                    <Button
                      color={skill.seniority === 2 ? "success" : "default"}
                      onClick={() => setSkill({ ...skill, seniority: 2 })}
                    >
                      Intermediate
                    </Button>
                    <Button
                      color={skill.seniority === 3 ? "success" : "default"}
                      onClick={() => setSkill({ ...skill, seniority: 3 })}
                    >
                      Comfortable
                    </Button>
                    <Button
                      color={skill.seniority === 4 ? "success" : "default"}
                      onClick={() => setSkill({ ...skill, seniority: 4 })}
                    >
                      Professional
                    </Button>
                  </ButtonGroup>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="light"
                  onPress={() => {
                    setSkill({
                      name: "",
                      icon: 1,
                      seniority: 1,
                    });
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="default"
                  onPress={() => {
                    setProfile({
                      ...profile,
                      skills: [...profile.skills, skill],
                    });
                    setSkill({
                      name: "",
                      icon: 1,
                      seniority: 1,
                    });
                    onClose();
                  }}
                >
                  Add
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <div className="w-full h-full flex text-slate-200 justify-center">
        <div className="mx-auto fixed top-20 right-16 flex gap-3">
          <Button color="default" onClick={() => setProfile(props.profile)}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => console.log(profile)}>
            Save
          </Button>
        </div>
        <div className="flex flex-col pt-48 max-w-2xl">
          <div className="mx-auto">
            <Avatar
              showFallback
              isBordered
              src={profile.pic}
              className="w-40 h-40"
            />
          </div>
          <div className="mx-auto mt-4 flex gap-3">
            <Button
              variant="flat"
              size="sm"
              startContent={<PenFill />}
              color="success"
            >
              Change picture
            </Button>
          </div>
          <Divider className="mt-8 bg-slate-800" />
          <h5 className="text-xl mt-8 text-center text-slate-400">
            General info
          </h5>
          <div className="text-sm mx-24 mt-8 text-center flex flex-col items-between gap-3">
            <div className="flex gap-5 items-center">
              <p>I am a</p>
              <ButtonGroup className="ml-auto">
                <Button
                  variant={profile.type === "DEVELOPER" ? "solid" : "flat"}
                  color={profile.type === "DEVELOPER" ? "primary" : "default"}
                  onClick={() => setProfile({ ...profile, type: "DEVELOPER" })}
                >
                  Developer
                </Button>
                <Button
                  variant={profile.type === "RECRUITER" ? "solid" : "flat"}
                  color={profile.type === "RECRUITER" ? "primary" : "default"}
                  onClick={() => setProfile({ ...profile, type: "RECRUITER" })}
                >
                  Recruiter
                </Button>
              </ButtonGroup>
            </div>
            <Input
              label="Name"
              fullWidth={false}
              value={profile.name ?? "None"}
              onValueChange={(e) => setProfile({ ...profile, name: e })}
            />
            <Input
              label="Position"
              value={profile.position ?? "None"}
              onValueChange={(e) => setProfile({ ...profile, position: e })}
            />
            <Input
              label="Location"
              value={profile.location ?? "None"}
              onValueChange={(e) => setProfile({ ...profile, location: e })}
            />
            <div className="flex items-center justify-between">
              <p>Looking for job</p>
              <Switch
                value={profile.lookingForJob}
                onValueChange={(e) =>
                  setProfile({ ...profile, lookingForJob: e })
                }
              />
            </div>
          </div>
          <Divider className="mt-8 bg-slate-800" />
          <h5 className="text-xl mt-8 mb-8 text-center text-slate-400">
            Overview
          </h5>
          <Textarea
            label="Description"
            value={profile.description}
            maxLength={1200}
            onValueChange={(e) => setProfile({ ...profile, description: e })}
          />
          <div className="mx-24 mt-8 flex flex-col gap-6 justify-center text-sm">
            <Input
              label="Fin. expectations"
              startContent={<CurrencyDollar />}
              type="number"
              value={profile.finExpectations ?? 0}
              onValueChange={(e) =>
                setProfile({ ...profile, finExpectations: e })
              }
            />
            <Select
              isRequired
              label="Fin. expectation currency"
              defaultSelectedKeys={[profile.finExpectationsCurrency ?? "PLN"]}
              onSelectionChange={(e) => {
                const [value] = e;
                setProfile({ ...profile, finExpectationsCurrency: value });
              }}
            >
              <SelectItem key="PLN">PLN</SelectItem>
              <SelectItem key="USD">USD</SelectItem>
              <SelectItem key="EUR">EUR</SelectItem>
            </Select>
            <div className="flex items-center justify-between">
              <p>Show financial expectations</p>
              <Switch
                value={profile.settings.showFinExpectations}
                onValueChange={(e) =>
                  setProfile({
                    ...profile,
                    settings: { ...profile.settings, showFinExpectations: e },
                  })
                }
              />
            </div>
            <Input
              label="Experience (in years)"
              type="number"
              value={profile.experience ?? 0}
            />
          </div>
          <Divider className="mt-8 bg-slate-800" />
          <h5 className="text-xl mt-8 text-center text-slate-400">Skills</h5>
          <div className="grid grid-cols-2 gap-4 mt-8">
            <SkillChip
              title="C++"
              description="Intermediate level"
              icon={<Braces />}
            />
          </div>
          <Button
            size="lg"
            radius="full"
            variant="flat"
            isIconOnly
            className="mx-auto mt-6 text-xl"
            onClick={onOpen}
          >
            <Plus />
          </Button>
          <Divider className="mt-8 bg-slate-800" />
          <h5 className="text-xl mt-8 text-center text-slate-400">Projects</h5>
          <p className="mx-auto text-sm text-slate-600 text-center italic">
            Add any project, that You were a part of, or are atm
          </p>
          <div className="mt-6">
            <ProjectChip
              name="I.G.I. Origins"
              role="Level Designer"
              link="https://store.steampowered.com/app/1186430/IGI_Origins/"
              img="https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/1186430/header.jpg?t=1719144784"
            />
          </div>
          <Button
            size="lg"
            radius="full"
            variant="flat"
            isIconOnly
            className="mx-auto mt-6 text-xl"
          >
            <Plus />
          </Button>
        </div>
      </div>
    </>
  );
}
