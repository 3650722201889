import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Input,
} from "@nextui-org/react";
import React, { useState } from "react";
import { Lock, At } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { tryLogin } from "../services";

export default function Login(props) {
  const [message, setMessage] = useState(null);
  const emailRef = useRef();
  const passRef = useRef();

  const navigate = useNavigate();
  const handleLogin = async () => {
    setMessage(null);
    const result = await tryLogin(
      emailRef.current.value,
      passRef.current.value
    );
    if (result) {
      navigate("/");
    } else {
      setMessage("Authorization error.");
    }
  };

  return (
    <div className="flex justify-center mt-auto">
      <Card className="p-4">
        <CardHeader className="flex items-center justify-center mb-4">
          <p>Login</p>
        </CardHeader>
        <Divider />
        <CardBody className="flex flex-col">
          <p className="text-xs pb-1 pl-2">Email</p>
          <Input
            ref={emailRef}
            value={emailRef.current?.value}
            className="mb-4"
            startContent={<At />}
            type="email"
          />
          <p className="text-xs pb-1 pl-2">Password</p>
          <Input
            ref={passRef}
            value={passRef.current?.value}
            className="mb-2"
            startContent={<Lock />}
            type="password"
          />
        </CardBody>
        <Divider />
        <CardFooter className="flex flex-col text-xs">
          <p className="text-red-500 pb-3">{message}</p>
          <Button color="primary" onClick={handleLogin}>
            Log in
          </Button>
          <p className="mt-2">
            Don't have an account?{" "}
            <span
              className="text-blue-400 cursor-pointer"
              onClick={() => navigate("/register")}
            >
              Sign Up
            </span>
          </p>
        </CardFooter>
      </Card>
    </div>
  );
}
