import React, { useEffect, useState } from "react";
import { Avatar, Button, Divider, Spinner } from "@nextui-org/react";
import { EnvelopeFill, StarFill } from "react-bootstrap-icons";
import InfoChip from "./InfoChip";
import SkillChip from "./SkillChip";
import ProjectChip from "./ProjectChip";
import { useParams } from "react-router-dom";
import { fetchProfile } from "../services";

export default function Profile(props) {
  let { uid } = useParams();
  const [profile, setProfile] = useState(false);

  useEffect(() => {
    async function getData() {
      const data = await fetchProfile(uid);
      if (data) {
        setProfile(data);
      }
    }
    getData();
  }, []);

  return (
    <div className="w-full h-full flex text-slate-200 justify-center">
      {!profile && <Spinner size="lg" />}
      {profile && (
        <div className="flex flex-col pt-48 max-w-2xl">
          <div className="mx-auto">
            <Avatar
              showFallback
              isBordered
              src={profile.pic}
              className="w-40 h-40"
            />
          </div>
          <div className="mx-auto text-center flex flex-col items-center">
            <h1 className="text-xl mt-4">{profile.name}</h1>
            <p className="text-slate-500">{profile.position}</p>
            <div className="mt-4 flex gap-3">
              <Button
                variant="flat"
                size="sm"
                startContent={<EnvelopeFill />}
                color="success"
              >
                Message
              </Button>
              <Button isIconOnly size="sm" variant="flat" color="success">
                <StarFill />
              </Button>
            </div>
          </div>
          <Divider className="mt-8 bg-slate-800" />
          <h5 className="text-xl mt-8 text-center text-slate-400">Overview</h5>
          <p className="text-slate-400 mt-8 text-pretty">
            {profile.description}
          </p>
          <div className="mt-8 flex gap-6 justify-center">
            <InfoChip title="Exp" value={`${profile.experience} yrs`} />
            {profile.finExpectationsCurrency &&
              profile.finExpectationsCurrency !== 0 && (
                <InfoChip title="Fin. expectations" value="10 - 15k USD" />
              )}
            <InfoChip title="Availability" value={`${profile.availability}m`} />
            {profile.location && (
              <InfoChip title="Location" value={profile.location} />
            )}
          </div>
          <Divider className="mt-8 bg-slate-800" />
          <h5 className="text-xl mt-8 text-center text-slate-400">Skills</h5>
          <div className="grid grid-cols-2 gap-4 mt-8">SKILLS</div>
          <Divider className="mt-8 bg-slate-800" />
          <h5 className="text-xl mt-8 text-center text-slate-400">Projects</h5>
          <p className="mx-auto text-sm text-slate-600 text-center italic">
            I was/am a part of the following projects
          </p>
          <div className="mt-6">
            <ProjectChip
              name="I.G.I. Origins"
              role="Level Designer"
              link="https://store.steampowered.com/app/1186430/IGI_Origins/"
              img="https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/1186430/header.jpg?t=1719144784"
            />
            <ProjectChip
              name="Doom"
              role="Level Designer"
              link="https://store.steampowered.com/app/379720/DOOM/"
              img="https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/379720/header.jpg?t=1692892793"
            />
            <ProjectChip
              name="Splinter Cell: Conviction"
              role="Level Designer"
              link="https://store.steampowered.com/app/33220/Tom_Clancys_Splinter_Cell_Conviction/"
              img="https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/33220/header.jpg?t=1709317521"
            />
          </div>
        </div>
      )}
    </div>
  );
}
