import axios from "axios";
import { auth, analytics } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { logEvent } from "firebase/analytics";
const api = "http://localhost:3001";

export async function tryLogin(email, password) {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    if (res) {
      logEvent(analytics, "login");
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function tryRegister(email, password) {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    if (res) {
      logEvent(analytics, "sign_up");
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function fetchMyProfile() {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const result = await axios.get(`${api}/user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function fetchProfile(uid) {
  try {
    const result = await axios.get(`${api}/user?uid=${uid}`);
    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}
