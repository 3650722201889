import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Avatar,
  Badge,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import {
  BellFill,
  BoxArrowLeft,
  BriefcaseFill,
  ChatFill,
  GearWide,
  PencilFill,
  PersonFill,
  StarFill,
} from "react-bootstrap-icons";
import { auth } from "./../firebase";
import { useNavigate } from "react-router-dom";
import Logo from "../Logo.png";

export default function Navigation(props) {
  const navigate = useNavigate();
  const isLoggedIn = auth.currentUser !== null;
  return (
    <Navbar className="flex w-full fixed bg-transparent">
      <NavbarBrand onClick={() => navigate("/")} className="cursor-pointer">
        <img src={Logo} width={42} className="mr-2" alt="Hacker Hive Logo" />
        <p className="font-semibold text-slate-200">HackerHive</p>
      </NavbarBrand>
      {isLoggedIn ? (
        <NavbarContent
          className="ml-auto text-green-400 flex gap-2 text-lg"
          justify="end"
        >
          <NavbarItem className="cursor-pointer">
            <Dropdown
              classNames={{
                base: "mt-8",
                content: "bg-gray-900 text-slate-200",
              }}
            >
              <DropdownTrigger>
                <Badge
                  isInvisible={props.profile?.notifications.length === 0}
                  size="sm"
                  content="3"
                  color="danger"
                  showOutline={false}
                >
                  <DropdownTrigger>
                    <Button variant="light" isIconOnly>
                      <BellFill />
                    </Button>
                  </DropdownTrigger>
                </Badge>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Static Actions"
                emptyContent={
                  <p className="text-center">You have no notifications</p>
                }
              ></DropdownMenu>
            </Dropdown>
          </NavbarItem>
          <NavbarItem className="cursor-pointer">
            <Badge
              isInvisible
              size="sm"
              content="3"
              color="danger"
              showOutline={false}
            >
              <Button variant="light" isIconOnly>
                <ChatFill />
              </Button>
            </Badge>
          </NavbarItem>
          <NavbarItem className="cursor-pointer">
            <Dropdown classNames={{ content: "bg-gray-900 text-slate-200" }}>
              <DropdownTrigger>
                <Button variant="light" isIconOnly radius="full">
                  <Avatar isBordered src={props.profile?.pic} size="sm" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                <DropdownItem
                  startContent={<PersonFill />}
                  key="myprofile"
                  onClick={() => navigate(`/profile/${auth.currentUser.uid}`)}
                >
                  My profile
                </DropdownItem>
                <DropdownItem
                  startContent={<PencilFill />}
                  key="myprofile"
                  onClick={() => navigate(`/profile/edit`)}
                >
                  Edit profile
                </DropdownItem>
                <DropdownItem startContent={<StarFill />} key="shortlist">
                  Shortlist
                </DropdownItem>
                <DropdownItem startContent={<BriefcaseFill />} key="jobs">
                  Job offers
                </DropdownItem>
                <DropdownItem startContent={<GearWide />} key="settings">
                  Settings
                </DropdownItem>
                <DropdownItem
                  startContent={<BoxArrowLeft />}
                  key="logout"
                  className="text-danger"
                  color="danger"
                  onClick={async () => {
                    await auth.signOut();
                    navigate("/");
                  }}
                >
                  Log out
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarItem>
        </NavbarContent>
      ) : (
        <NavbarContent
          className="ml-auto text-green-400 flex gap-5 text-lg"
          justify="end"
        >
          <NavbarItem>
            <Button
              className="font-semibold"
              size="sm"
              color="default"
              onClick={() => navigate("/register")}
            >
              Sign Up
            </Button>
          </NavbarItem>
          <NavbarItem>
            <Button
              className="font-semibold"
              size="sm"
              color="primary"
              onClick={() => navigate("/login")}
            >
              Log In
            </Button>
          </NavbarItem>
        </NavbarContent>
      )}
    </Navbar>
  );
}
